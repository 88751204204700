import React from 'react'

// libraries
import { css } from '@emotion/core';

// components
import { Log } from 'pages/changelog';
import Markdown from './Markdown';

// style
import { Vars } from 'style';

// images
import bug from 'images/changelog/bug.svg';
import compass from 'images/changelog/compass.svg';
import bang from 'images/changelog/bang.svg';
import sdk from 'images/changelog/sdk.svg';

export default function ChangeLog({ log }: { log: Log }) {
  const {
    date,
    build,
    description = '',
    changes
  } = log;

  return (
    <div css={logWrapper}>
      <div css={logHeader}>
        <h2>
          {dateFormat(date)}
          {build && ` · ${build}`}
        </h2>
      </div>
      {description && <p css={logDescription}>{description}</p>}
      {Object.keys(log).map(key => {
          let icon = compass;
          if (key.toLowerCase().includes("features")) icon = bang;
          if (key.toLowerCase().includes("bug")) icon = bug;
          if (key.toLowerCase().includes("sdk")) icon = sdk;

          return key !== "date" && key !== "build" && key !== "description" && (
            <div key={`${build}-${key}`} css={sectionWrapper}>
              <div css={leftDecoration}>
                <img css={leftIcon} src={icon} alt={''} />
                <div css={sideThing} />
              </div>
              <div css={logContent}>
                <h4 css={listHeader}>{key.toUpperCase()}</h4>
                <Markdown escape={false}>{log[key]}</Markdown>
              </div>
            </div>
          )
        }
      )}
      {changes && (
        <div css={sectionWrapper}>
          <div css={leftDecoration}>
            <img css={leftIcon} src={compass} alt="compass" />
            <div css={sideThing} />
          </div>
          <div css={logContent}>
            <h4 css={listHeader}>WHAT'S NEW</h4>
            <Markdown escape={false}>{changes}</Markdown>
          </div>
        </div>
      )}
    </div>
  );
}

function dateFormat(date: Date) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(date);
}

// styles
const logWrapper = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 120rem;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

const sectionWrapper = css`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const leftDecoration = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const leftIcon = css`
  display: block;
  height: 4rem;
  width: 4rem;
  margin: 0.5rem 0;
`;

const sideThing = css`
  width: 0;
  position: relative;
  margin: 0 2rem;
  border: 1px solid rgb(74, 75, 77);
  border-top: transparent;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    left: -0.4rem;
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    background-color: rgb(74, 75, 77);
    border-radius: 50%;
  }
`;

const logContent = css`
  flex: 1;
  margin-left: 2rem;
  list-style-position: inside;

  > ul {
    padding: 0;
  }
  > div > ul {
    padding: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

const logHeader = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

const logDescription = css`
  flex: 5;
  margin-right: 2rem;
`;

const listHeader = css`
  &::after {
    content: '';
    display: block;
    width: 5rem;
    height: 2px;
    background-color: ${Vars.PositiveColor};
    z-index: 0;
    margin: 1rem 0;
  }
`;
